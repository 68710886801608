// パンくずを複数与えられるようにする
type MultipleBreadcrumbs = BreadcrumbItem[][];

type BreadcrumbItem = {
  name: string;
  path?: string;
};

// 共通データーはこの形式がベストらしい
// ref: https://nuxt.com/docs/getting-started/state-management#best-practices
const _useBreadcrombs = () =>
  useState<MultipleBreadcrumbs>("breadcrumbs", () => []);

// BreadcrumbItem[]を判定する
function isBreadcrumbItemArray(
  list: MultipleBreadcrumbs | BreadcrumbItem[],
): list is BreadcrumbItem[] {
  return list.length > 0 && "name" in list[0];
}

/**
 * 強制的にパンくずをリセットして更新する
 * @param list
 */
export function useBreadcrombsForce(
  list: MultipleBreadcrumbs | BreadcrumbItem[] = [],
) {
  const breadcrumbs = _useBreadcrombs();

  if (isBreadcrumbItemArray(list)) {
    list = [list];
  }

  breadcrumbs.value = list;
}

type ArgsBread = MultipleBreadcrumbs | BreadcrumbItem[];
type Args = ArgsBread | (() => ArgsBread);

export default function (__list: Args = []) {
  const breadcrumbs = _useBreadcrombs();

  // const deactivated = ref(false);

  const computedBreadcrumb = computed(
    typeof __list === "function" ? __list : () => __list,
  );

  // onDeactivated(() => {
  //   deactivated.value = true;
  // });

  // onActivated(() => {
  //   deactivated.value = false;
  // });

  function setBreadcrumb(_list: MultipleBreadcrumbs | BreadcrumbItem[] = []) {
    // BreadcrumbItem[]の場合は配列に変換する
    if (isBreadcrumbItemArray(_list)) {
      _list = [_list];
    }
    return _list;
  }

  watch(
    () => computedBreadcrumb.value,
    (list) => {
      breadcrumbs.value = setBreadcrumb(list);
    },
    { immediate: true },
  );

  onMounted(() => {
    breadcrumbs.value = setBreadcrumb(computedBreadcrumb.value);
  });

  let list = computedBreadcrumb.value;
  if (list.length) {
    // JSON-LDのObject形式に変換する
    // BreadcrumbItem[]の場合は配列に変換する
    if (isBreadcrumbItemArray(list)) {
      list = [list];
    }

    list.forEach((list, idx) => {
      const itemListElement: { name: string; item: string }[] = [];
      list.forEach((item) => {
        itemListElement.push({
          name: item.name,
          item: item.path ?? "",
        });
      });

      const data: { itemListElement: any; "@id"?: any } = {
        itemListElement,
      };

      // 1つ目以降は@idを連番付与する
      if (idx !== 0) {
        data["@id"] = `#sub-breadcrumbs-${idx}`;
      }

      useSchemaOrg(defineBreadcrumb(data));
    });
  }

  return {
    breadcrumbs,
  };
}
